body {
  margin: 0;
  padding: 0;
  font-family: Inter;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}
a {
  display: block;
}
ul {
  margin: 0;
  margin-inline: 0 !important;
  padding: 0;
  list-style: none;
}
.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}
input {
  background-color: transparent;
  width: 100%;
}
input:focus {
  outline: none;
}
table {
  table-layout: fixed;
}
.alice-carousel__dots-item {
  cursor: pointer;
}
.ps-menu-icon {
  width: unset !important;
  min-width: unset !important;
  justify-content: start !important;
  margin: 0 !important;
}
.ps-menu-button,
.ps-menu-icon {
  height: unset !important;
}
.ps-menu-button:hover {
  background-color: unset !important;
}
.ps-sidebar-container,
.ps-submenu-content {
  background-color: transparent !important;
}
.ps-collapsed .ps-menu-icon {
  min-width: 100% !important;
  justify-content: center !important;
}
.ps-collapsed {
  width: 80px !important;
}
.ps-sidebar-container,
.ps-menu-root,
.ps-menu-root > ul {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ps-sidebar-root {
  min-width: unset !important;
  border: none !important;
}
.ps-collapsed,
.ps-collapsed .ps-menu-root,
.ps-collapsed .ps-menu-button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: unset !important;
  margin-right: unset !important;
}
.ps-collapsed ul {
  align-items: center;
}
.ps-menu-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ps-sidebar-container::-webkit-scrollbar {
  width: 2px;
}

.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}